<template>
  <div class="app-context expanded">
    <div class="context complex">
      <div class="c-context">
        <directive :directiveInfo="directiveInfo"></directive>
        <v-container class="changepw-container">
          <v-row class="changepw-container-row">
            <v-col class="changepw-container-row-title" md="4" cols="12">
              현재 비밀번호
            </v-col>
            <v-col md="8" cols="12">
              <v-row>
                <v-col md="8" class="changepw-container-col">
                  <div class="input-wrap">
                    <input
                      style="width: 100%"
                      type="password"
                      placeholder="현재 비밀번호를 입력해주세요."
                      v-model="form.currentPw.value"
                      @keyup="checkCurrentPw()"
                      @blur="checkCurrentPw()"
                    />
                  </div>
                  <p class="warn-message" v-if="form.currentPw.hasError">
                    <img src="@/assets/images/icons/error_24dp.svg" />
                    <span>{{ form.currentPw.errorTxt }}</span>
                  </p>
                </v-col>
                <v-col md="4" class="changepw-container-col-over">
                  <v-btn
                    class="changepw-container-col-over-btn"
                    rounded
                    color="#146f83"
                    width="120"
                    large
                    elevation="0"
                    dark
                    :class="{
                      complete: form.currentPw.done && form.currentPw.flag,
                    }"
                    @click="confirmPw()"
                    :disabled="form.currentPw.done && form.currentPw.flag"
                    >확인</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="changepw-container-row">
            <v-col class="changepw-container-row-title" md="4" cols="12">
              변경할 비밀번호
            </v-col>
            <v-col md="8" cols="12">
              <v-row>
                <v-col md="8" class="changepw-container-col">
                  <div class="input-wrap">
                    <input
                      style="width: 100%"
                      type="password"
                      placeholder="변경할 비밀번호를 입력해주세요."
                      v-model="form.password.value"
                      @keyup="checkPw()"
                      @blur="checkPw()"
                    />
                  </div>
                  <p class="warn-message" v-if="form.password.hasError">
                    <img src="@/assets/images/icons/error_24dp.svg" />
                    <span>{{ form.password.errorTxt }}</span>
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="changepw-container-row">
            <v-col class="changepw-container-row-title" md="4" cols="12">
              비밀번호 확인
            </v-col>
            <v-col md="8" cols="12">
              <v-row>
                <v-col md="8" class="changepw-container-col">
                  <div class="input-wrap">
                    <input
                      style="width: 100%"
                      type="password"
                      placeholder="비밀번호를 한번 더 입력해주세요."
                      v-model="form.passwordConfirm.value"
                      @keyup="checkPwConfirm()"
                      @blur="checkPwConfirm()"
                    />
                  </div>
                  <p class="warn-message" v-if="form.passwordConfirm.hasError">
                    <img src="@/assets/images/icons/error_24dp.svg" />
                    <span>{{ form.passwordConfirm.errorTxt }}</span>
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <div class="join document">
          <div class="document-btn-wrap">
            <div
              class="item iconIn"
              @click="next()"
              :class="{
                active:
                  form.currentPw.flag &&
                  form.currentPw.done &&
                  form.password.flag &&
                  form.passwordConfirm.flag,
              }"
            >
              <img src="@/assets/images/icons/content-save-outline.svg" />
              <!-- <img src="@/assets/images/menuIcon/save.svg" /> -->
              <!-- <span class="material-icons-outlined google-icon">save</span> -->
              <p>정보저장</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import Directive from "@/components/join/Directive";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Directive,
    DefaultPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      popupSet: {},
      form: {
        currentPw: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "현재 비밀번호를 입력해주세요.",
          done: false,
        },
        password: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "변경할 비밀번호를 입력해주세요.",
        },
        passwordConfirm: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "비밀번호 확인을 입력해주세요.",
        },
      },
      directiveInfo: {
        title: "비밀번호 변경",
        isTitleNewLine: false,
        content:
          "현재비밀번호와 변경할 비밀번호를 입력해주세요.\n입력 후 저장하기 버튼을 누르면 변경완료됩니다.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    // }
  },
  mounted() {
    // document.querySelector(".pw-change").classList.add("active");
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("auth", ["GET_CHECK_RESULT"]),
  },
  methods: {
    ...mapActions("auth", ["CHANGE_PASSWORD_OWN", "CHECK_PASSWORD"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    checkFlag() {
      if (
        !this.form.currentPw.flag ||
        !this.form.currentPw.done ||
        !this.form.password.flag ||
        !this.form.passwordConfirm.flag
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        if (!this.form.currentPw.flag || !this.form.currentPw.done) {
          this.form.currentPw.hasError = true;
        }
        if (!this.form.currentPw.done) {
          this.form.currentPw.hasError = true;
          this.form.currentPw.errorTxt = "비밀번호 확인을 해주세요.";
        }
        if (!this.form.password.flag) {
          this.form.password.hasError = true;
        }
        if (!this.form.passwordConfirm.flag) {
          this.form.passwordConfirm.hasError = true;
        }
        return false;
      }
      return true;
    },
    async next() {
      if (this.checkFlag()) {
        try {
          const payload = {
            id: localStorage.getItem("drId"),
            passwd: this.form.password.value,
          };

          // 유효성 검사
          // 1. 아이디
          if (payload.id === null || payload.id === undefined) payload.id = "";

          var idBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

          if (!idBoo.test(payload.id)) {
            this.SET_POPUP(true);
            this.popupSet.title = "비밀번호 변경 실패";
            this.popupSet.content = "아이디를 확인해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          // 2. 패스워드
          if (payload.passwd === null || payload.passwd === undefined)
            payload.passwd = "";

          if (payload.passwd.length < 4 || payload.passwd.length > 20) {
            this.SET_POPUP(true);
            this.popupSet.title = "비밀번호 변경 실패";
            this.popupSet.content = "비밀번호를 20자 내로 입력해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            return;
          }

          await this.CHANGE_PASSWORD_OWN(payload).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "비밀번호 변경완료";
            this.popupSet.content = "비밀번호가 성공적으로 변경되었습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.destination = true;
            this.popupSet.nextLink = "/";
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.cancelBtnText = undefined;
          });
        } catch (error) {
          console.log("error: ", error);
        }
      }
    },
    async confirmPw() {
      try {
        const payload = {
          passwd: this.form.currentPw.value,
        };
        await this.CHECK_PASSWORD(payload).then(() => {
          if (this.GET_CHECK_RESULT) {
            this.form.currentPw.done = true;
            this.form.currentPw.flag = true;
            this.form.currentPw.hasError = false;
            this.SET_POPUP(true);
            this.popupSet.title = "비밀번호 확인 성공";
            this.popupSet.content = "변경할 비밀번호를 입력해주세요.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          } else {
            this.form.currentPw.done = false;
            this.form.currentPw.flag = false;
            this.form.currentPw.hasError = true;
            this.form.currentPw.errorTxt = "비밀번호 확인 실패";
            this.SET_POPUP(true);
            this.popupSet.title = "비밀번호 확인 실패";
            this.popupSet.content = "현재 비밀번호와 일치하지 않습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        });
      } catch (error) {
        console.log("error :", error);
      }
    },
    checkCurrentPw() {
      this.form.currentPw.flag = false;
      this.form.currentPw.hasError = true;
      this.form.currentPw.done = false;
      const currentPw = this.form.currentPw.value.replace(/\s/gi, "");
      if (currentPw === "") {
        this.form.currentPw.flag = false;
        this.form.currentPw.hasError = true;
        this.form.currentPw.errorTxt = "현재 비밀번호를 입력해주세요.";
        return false;
      }
      const isCurrentPw = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
      if (!isCurrentPw.test(currentPw)) {
        this.form.currentPw.errorTxt = "비밀번호 형식이 잘못되었습니다.";
        this.form.currentPw.flag = false;
        this.form.currentPw.hasError = true;
        return false;
      }
      this.form.currentPw.flag = true;
      this.form.currentPw.hasError = false;
      this.form.currentPw.errorTxt = "";
      return true;
    },
    checkPw() {
      this.form.password.flag = false;
      this.form.password.hasError = true;
      const pw = this.form.password.value.replace(/\s/gi, "");
      if (pw === "") {
        this.form.password.flag = false;
        this.form.password.hasError = true;
        this.form.password.errorTxt = "비밀번호를 입력해주세요.";
        return false;
      }
      const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
      if (!isPw.test(pw)) {
        this.form.password.errorTxt =
          "영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요.";
        this.form.password.flag = false;
        this.form.password.hasError = true;
        return false;
      }
      this.form.password.flag = true;
      this.form.password.hasError = false;
      this.form.password.errorTxt = "";
      return true;
    },
    checkPwConfirm() {
      this.form.passwordConfirm.flag = false;
      this.form.passwordConfirm.hasError = true;
      const pwConfirm = this.form.passwordConfirm.value.replace(/\s/gi, "");
      if (pwConfirm === "") {
        this.form.passwordConfirm.flag = false;
        this.form.passwordConfirm.hasError = true;
        this.form.passwordConfirm.errorTxt = "비밀번호 확인을 입력해주세요.";
        return false;
      }
      if (pwConfirm !== this.form.passwordConfirm.value) {
        this.form.passwordConfirm.flag = false;
        this.form.passwordConfirm.hasError = true;
        this.form.passwordConfirm.errorTxt = "비밀번호가 일치하지 않습니다.";
        return false;
      }
      this.form.passwordConfirm.flag = true;
      this.form.passwordConfirm.hasError = false;
      this.form.passwordConfirm.errorTxt = "";
      return true;
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    initAuthInfo() {
      //console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
@import "@/assets/css/sideContext";
@import "@/assets/scss/ChangePw";
</style>
